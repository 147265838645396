import React from "react";

import PageHeader from "../components/PageHeader";
import SectionHeading from "../components/SectionHeading";

const Features1 = () => {
  return (
    <>
      {/* page header - start */}
      <PageHeader title="Gillidanda" pages={[]} />
      {/* page header - end */}

      {/* feature section - start */}
      <div className="feature-section feature-section-1 feature-section-spacing-3">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-10 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <SectionHeading icon="" heading="" subHeading="Gillidanda" />
                  <div className="icon-text-1-group">
                    <div className="icon-text-1">
                      <div>
                        <p className="c-grey">
                          Gilli Danda (also spelled Gulli-Danda) also known as
                          Viti Dandu, Kitti-Pul and by other variations, is a
                          sport originating from the Indian subcontinent, played
                          in the rural areas and small towns all over South Asia
                          as well as Cambodia, Iran, Turkey, South Africa,
                          Italy, Poland, and in some Caribbean islands like
                          Cuba. The game is played with two sticks: a large one
                          called a danda (Dandi in Nepali, Dandu/दांडू/ದಾಂಡು in
                          Marathi, Kittipul/கிட்டிப்புள் in Tamil and Kannada,
                          കോൽ in Malayalam), which is used to hit a smaller one,
                          the gilli (Biyo in Nepali, Viti/विटी in Marathi,
                          kittikol/ கிட்டிக்கோல் in Tamil and Chinni/ಚಿನ್ನಿ in
                          Kannada, കുറ്റി in Malayalam). Other than using a
                          smaller target stick, rather than a ball, it bears
                          many similarities to bat and ball games such as
                          cricket and baseball.
                        </p>

                        <p className="c-grey">
                          Gullidanda is an ancient sport, possibly with origins
                          over 2500 years ago.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* feature section - end */}

      {/* feature section - start */}
      <div className="feature-section feature-section-1 feature-section-spacing-3">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-5 offset-lg-0 col-10 offset-1">
                <div className="feature-section-image">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/a/a8/Guli_danda_on_Ganges_Ghats_in_Varanasi.jpg"
                    className="phone"
                    alt="feature-fore"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <SectionHeading icon="" heading="" subHeading="Etimology" />
                  <div className="icon-text-1-group">
                    <div className="icon-text-1">
                      <div>
                        <p className="c-grey">
                          Gillidanda is derived from ghaṭikā [ghaṭ a+ka],
                          literally "tip-cat". An explanatory definition is
                          given in the commentary as ghaṭikā is "a game played
                          using two sticks: one long and the other short. It is
                          played by hitting the shorter stick with the longer
                          one." Ghaṭikā is still known to countries from the
                          Indian subcontinent and south Asian countries like
                          Bangladesh and India. In Bangladesh, it is known as
                          ḍāṅguli khelā; while in Nepali, it is known as
                          Dandi-Biyo (डण्डी बियो), which is a similar game. The
                          longer stick in a ḍāṅguli khelā, should be about 1+1⁄2
                          ft (0.46 m) and the shorter stick about 6 in (150 mm).
                          There are certain rules for preparing the sticks and
                          playing the game with them.
                        </p>

                        <p className="c-grey">
                          Gillidanda is known by various other names: it is
                          called Tipcat in English, Iti-Dakar (اٽي ڏڪر) in
                          Sindhi, guli-badi (ଗୁଲି ବାଡ଼ି) in Odia (regional
                          variations dabalapua ଡାବଲପୁଆ and ପିଲବାଡ଼ି pilabadi in
                          Phulbani and guti-dabula ଗୁଟିଡାବୁଳ in Balasore),
                          gulli-ṭāṇ (𑂏𑂳𑂪𑂹𑂪𑂲 𑂗𑂰𑂝) in Bhojpuri, alak-doulak (الک
                          دولک) in Persian, dānggűli (ডাঙ্গুলি) in Bengali, Tang
                          Guti (টাং গুটি) in Assamese, chinni-kolu ಚಿನ್ನಿ ಕೋಲು
                          in Kannada, kuttiyum kolum in Malayalam, vitti-dandu
                          विट्टी दांडू in Marathi, Koyando-bal(कोयंडो बाल) in
                          Konkani, kitti-pul (கிட்டி-புல்) in Tamil, Gooti-Billa
                          (Andhra Pradesh) or Karra-Billa (Andhra Pradesh) or
                          Billam-Godu (Andhra Pradesh) or chirra-gonay (in
                          Telangana) in Telugu, Gulli-Danda (گُلی ڈنڈا/ਗੁੱਲ਼ੀ
                          ਡੰਡਾ) in Punjabi, Geeti Danna (گیٹی ڈنا) in Saraiki,
                          Ampra kaakay(am pra ka kay) in Pashto, Kon ko in
                          Cambodian, Pathel Lele in Indonesian, syatong in
                          Tagalog, awe petew in Ilonggo, çelikçomak in Turkish,
                          ciang sat in Zomi language, "Đánh Trỏng" or "Đánh
                          Khăng" in Vietnam, Quimbumbia in Cuba and Lippa in
                          Italy.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* feature section - end */}

      {/* feature section - start */}
      <div className="feature-section feature-section-1 feature-section-spacing-3">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-5 offset-lg-0 col-10 offset-1">
                <div className="feature-section-image">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/a/af/Gilli-danda.jpg"
                    className="Phone"
                    alt="feature-fore"
                  />
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <SectionHeading icon="" heading="" subHeading="Rules" />
                  <div className="icon-text-1-group">
                    <div className="icon-text-1">
                      <div>
                        <p className="c-grey">
                          "Gillidanda" is played with two pieces of equipment –
                          a danda, being a long wooden stick, and a gilli, a
                          small oval-shaped piece of wood. It is played with
                          four or more players of even numbers. Standing in a
                          small circle, the player balances the gilli on a stone
                          in an inclined manner (somewhat like a see-saw) with
                          one end of the gilli touching the ground while the
                          other end is in the air. The player then uses the
                          danda to hit the gilli at the raised end, which flips
                          it into the air. While it is in the air, the player
                          strikes the gilli, hitting it as far as possible.
                          Having struck the gilli, the player is required to run
                          and touch a pre-agreed point outside the circle before
                          the gilli is retrieved by an opponent. There are no
                          specific dimensions of gillidanda and it does not have
                          a limited number of players.
                        </p>

                        <p className="c-grey">
                          The gilli becomes airborne after it is struck. If a
                          fielder from the opposing team catches the gilli, the
                          striker is out. If the gilli lands on the ground, the
                          fielder closest to the gilli has one chance to hit the
                          danda (which has to be placed on top of the circle
                          used) with a throw (similar to a run out in cricket).
                          If the fielder is successful, the striker is out; if
                          not, the striker scores one point and gets another
                          opportunity to strike. The team (or individual) with
                          the most points wins the game. If the striker fails to
                          hit the gilli in three tries, the striker is out
                          (similar to a strikeout in baseball). After the gilli
                          has been struck, the opposing players need to return
                          to the circle or, in the best case, catch it in
                          mid-air without it hitting the ground – this was
                          believed to have later evolved into a Catch Out in
                          cricket and baseball.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* feature section - end */}

      {/* feature section - start */}
      <div className="feature-section feature-section-1 feature-section-spacing-3">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-10 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <SectionHeading icon="" heading="" subHeading="Variations" />
                  <div className="icon-text-1-group">
                    <div className="icon-text-1">
                      <div>
                        <p className="c-grey">
                          As an amateur youth sport, gilli danda has many
                          regional variations. In some versions, the number of
                          points a striker score depends on the distance the
                          gilli falls from the striking point. The distance is
                          measured in terms of the length of the danda, or in
                          some cases the length of the gilli. Scoring also
                          depends on how many times the gilli was hit in the air
                          in one strike. If it travels a certain distance with
                          two mid-air strikes, the total points are doubled. If
                          the gilli is not struck far enough the player has to
                          pick it up and try again. Shobhit Maurya is a world
                          champion of gilli danda at present with several world
                          records in his name. The UNESCO Advisory committee and
                          the International Council of Traditional Sports and
                          Games (ICTSG) are keen to revive and promote all such
                          traditional sports which are almost dying in the
                          World.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* feature section - end */}
    </>
  );
};

export default Features1;
