import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Header from "./layout/Header";
import Index from "./pages/Index";
import Media from "./pages/Media";
import Footer from "./layout/Footer";
import CustomScrollbar from "./components/CustomScrollbar";
import Preloader from "./components/Preloader";
import Gillidanda from "./pages/Gillidanda";
import Jachigi from "./pages/Jachigi";
import KibbelKabbel from "./pages/Kibbel-Kabbel";
import KondaKondi from "./pages/Konda-Kondi";
import Lippa from "./pages/Lippa";
import Shatong from "./pages/Shatong";
import Tipcat from "./pages/Tipcat";
import Pandolo from "./pages/Pandolo";
import DandiBiyo from "./pages/DandiBiyo";
import Quimbumbia from "./pages/Quimbumbia";
import Rules from "./pages/Rules";

const Markup = () => {
  return (
    <>
      <Preloader />
      <CustomScrollbar />
      <div className="main-wrapper">
        <Header type={useLocation().pathname !== "/" ? "navigation-1" : ""} />
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/prensa" element={<Media />} />
          <Route path="/gillidanda" element={<Gillidanda />} />
          <Route path="/jachigi" element={<Jachigi />} />
          <Route path="/kibbelkabbel" element={<KibbelKabbel />} />
          <Route path="/kondakondi" element={<KondaKondi />} />
          <Route path="/syatong" element={<Shatong />} />
          <Route path="/lippa" element={<Lippa />} />
          <Route path="/tipcat" element={<Tipcat />} />
          <Route path="/pandolo" element={<Pandolo />} />
          <Route path="/dandibiyo" element={<DandiBiyo />} />
          <Route path="/quimbumbia" element={<Quimbumbia />} />
          <Route path="/reglas" element={<Rules />} />
        </Routes>
        <Footer />
      </div>
    </>
  );
};

export default Markup;
