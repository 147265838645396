import React from "react";

import PageHeader from "../components/PageHeader";
import SectionHeading from "../components/SectionHeading";

const Features1 = () => {
  return (
    <>
      {/* page header - start */}
      <PageHeader title="Shatong" pages={[]} />
      {/* page header - end */}

      {/* feature section - end */}

      {/* feature section - start */}
      <div className="feature-section feature-section-1 feature-section-spacing-3">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-5 offset-lg-0 col-10 offset-1">
                <div className="feature-section-image">
                  <img
                    src="https://steemitimages.com/DQmdYRB85wuMabL9xzwwvMNPHTBbdT3iWLbhg2noa3ndTeL/image.png"
                    className="phone"
                    alt="feature-fore"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <SectionHeading icon="" heading="" subHeading="How to play" />
                  <div className="icon-text-1-group">
                    <div className="icon-text-1">
                      <div>
                        <p className="c-grey">
                          A Filipino Kids Past Time: One Of The Traditional
                          Games.
                        </p>

                        <p className="c-grey">
                          Stick game, better be good at it - Two players, one
                          flat stick (usually 3') and one short flat piece of
                          wood (4" usually a piece cut from the flat stick).
                        </p>
                        <p className="c-grey">
                          Player A hitter and Player B as the catcher. Played
                          outside on the ground where you dig a small square
                          hole (slanted) where you put the small wood so it
                          sticks out.
                        </p>
                        <p className="c-grey">
                          Player A hits the wood with the stick so it catches
                          air enough to be hit by the stick.
                        </p>
                        <p className="c-grey">
                          The further the wood gets hit the more points you get
                          usually counted by the number of stick length
                        </p>
                        <p className="c-grey">
                          Player B on the other hand has to anticipate and catch
                          the small piece of wood to nullify the points and
                          become his turn OR looks forward to Player A to miss
                          hitting the wood.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* feature section - end */}
    </>
  );
};

export default Features1;
