export const TRANSLATIONS_GAL = {
  page: {
    title: "Museo Internacional da Billarda",
  },
  banner: {
    title: "Museo Internacional da Billarda",
    text: "Un Museo único e singular. Recursos naturais e dixitalización. Madeira de Castiñeiro e códigos QR’s. Un antigo galiñeiro derruído e restaurado con pedra e lousa da zona. Un proxecto circular.",
  },
  origin: {
    title: "Orixe da billarda",
    subtitle: "Os inicios do xogo",
    text: "O termo billarda vén do francés 'bilard' (bastón de xogar). A orixe remóntase á Idade Media, estando documentado no Libro de Apolonio (Mester de Clerecía) e nas Cantigas de Santa María de Alfonso X, ambas as obras do século XIII.",
  },
  player: {
    title: "O xogador máis ilustre",
    subtitle: "Alfonso X El Sabio",
    text1:
      "O xogador máis ilustre do Castelo de Maceda foi o fillo do rei Fernando III e de Beatriz de Suabia. Nacido no ano 1221 en Toledo, o pequeno Alfonso foi posto ao coidado do nobre García Fernández de Villamayor e da esposa de leste, Maior Arias, e foi enviado a Galicia para pasar parte da súa infancia.",
    text2:
      "Aínda que outros municipios como Allariz reivindican esa mesma condición, en Maceda non albergan dúbidas de que as súas terras e o seu castelo foron o destino da comitiva que acompañou ao herdeiro ao trono, e dan por seguro que o futuro Alfonso X, que pasaría á historia co sobrenome do Sabio, viviu na súa fortaleza ata a idade de 11 anos.",
    text3:
      "Así o sustentaría o feito de que en agradecemento polos servizos prestados o rei Fernando III concedese unha vila en Maceda a García Fernández de Villamayor",
  },
  game: {
    title: "O xogo da Billarda",
    subtitle: "Como funciona?",
    text1:
      "Foi un dos xogos máis coñecidos e populares de Galicia e por tanto tamén na nosa aldea de Castiñeiro, que pertence á parroquia de Villarda (con V) no municipio ourensán de San Xoán de Río. Xa se xogaba no século XIX, no xornal de Lugo A Monteira do ano 1889 dicíase que era un xogo de moda actual entre os nenos.",
    text2:
      "Ademais afirmaba que non se sabía quen era o inventor, pero que non debía ser un calquera, porque tiña máis utilidades das que parecía, dálle axilidade ao corpo, forza ao brazo, e insignia a calcular as distancias a ollo.",
    text3:
      "Practícase tanto en Galicia como noutros lugares do país. Así, en Canarias e Estremadura chámase Billarda; en Castela- León, Estornija; en Asturias, Liriu; no País Vasco Txirikila e en Cataluña, Bèlit. Internacionalmente podemos atopalo en Paquistán e na India, chámase Guli-danda ou Gilidanda; en Corea, Jachigi; en Alemaña, Kibbel-kabbel; en Malaisia, konda kondi; en Italia, Lippa; en Filipinas, Syatong; en Inglaterra, Escocia e Irlanda e USA Tip-cat ou,Piggy; en Eslovenia, Pandolo; en Nepal, Dandi Biyo; en Cuba, Qumbumbia.",
  },
  age: {
    title: "A lonxevidade",
    subtitle: "Xogo como parte do noso legado cultural interxeracional",
    text1:
      "Alfonso X «o Sabio» rei de Castela, León e Galicia entre 1252 e 1284, era un home que apoiaba o coñecemento e que cría que xogar facíache vivir máis anos.",
    text2:
      "A Juan Pérez, con 87 anos, chámanlle o 'patriarca' da billarda. Reside en Castiñeiro e segue xogando con quinceañeros da Ribeira Sacra e o Macizo Central ourensán.",
    text3:
      "Nesta aldea, con 6 censados, naceu Aldealista: 'Os pobos non poden desaparecer'. Aquí nace tamén o Museo Internacional da Billarda, xunto a un campo para o xogo. Un Museo único e singular. Recursos naturais e dixitalización. Madeira de Castiñeiro e códigos QR’s. Un antigo galiñeiro derruído e restaurado con pedra e lousa da zona. Un proxecto circular.",
    text4:
      "O Proxecto de Billarda no Concello De San Xoan de Río resultou ser un dos gañadoras na XIII edición dos Premios Galicia á Xestión Deportiva.",
  },
  rules: {
    title: "Regras",
    subtitle: "Como se xoga á billarda en Galicia?",
    text1:
      "O obxectivo é superar a liña do varal coa billarda. Para isto os palanadores ou palanadoras sitúanse na zona de saque e desde alí golpean a billarda, que está no chan, nunha das súas puntas para que se eleve. Cando está no aire hai que darlle outro golpe para desprazala.",
    text2:
      "A orde de lanzamento é consecutivo. En cada quenda, o xogador ou xogadora ten unha única opción válida de golpe, é dicir; sempre que toque a billarda co palán, aínda que non se eleve, considérase opción de tiro e a quenda pasaría ao seguinte. No momento en que a billarda está no aire pódese golpear tantas veces como se poida. Así mesmo, cando a billarda é golpeada despois de tocar o chan, o xogador/para deberá volver ao lugar onde tocou por primeira vez o chan.",
    text3:
      "Se a billarda é golpeada e sae directamente fose do terreo de xogo, deberase recoller e situala, de novo, no mesmo lugar para dar o seguinte golpe. Se antes de saír dos límites d campo toca o terreo de xogo, a billarda deberá ser situada sobre a liña, no lugar p onde saíu, sempre que sexa polos laterais do campo. No caso de que a billarda salga pola liña de fondo, deberá recollerse e colocala no lugar do último golpe. Por último, cada vez que a billarda entre na liña do varal súmanse puntos.",
  },
  footer: {
    copyright: "Todos os dereitos reservados",
  },
};
