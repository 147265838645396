import React from "react";

import PageHeader from "../components/PageHeader";
import SectionHeading from "../components/SectionHeading";

const Features1 = () => {
  return (
    <>
      {/* page header - start */}
      <PageHeader title="Dandi Biyo" pages={[]} />
      {/* page header - end */}

      {/* feature section - start */}
      <div className="feature-section feature-section-1 feature-section-spacing-3">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-10 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <SectionHeading icon="" heading="" subHeading="Dandi Biyo" />
                  <div className="icon-text-1-group">
                    <div className="icon-text-1">
                      <div>
                        <p className="c-grey">
                          Dandi biyo (Nepali: [[:ne:डन्डी बियो] pronounced
                          [ˈɖʌɳɖi ˈbijo]) is a game played in Nepal which was
                          considered the de facto national game until 23 May
                          2017, when volleyball was declared as the national
                          sport. Dandi biyo is played with a stick (dandi) about
                          2 feet (61 cm) long and a wooden pin (biyo) about 6
                          inches (15 cm) long. The pin is a small wooden stick
                          with pointed ends. The game is similar to the Indian
                          game gilli danda. The government has not implemented
                          any policies for the preservation of dandi biyo, and
                          with decreasing players the game is expected to be
                          extinct soon.
                        </p>

                        <p className="c-grey">
                          Gullidanda is an ancient sport, possibly with origins
                          over 2500 years ago.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* feature section - end */}

      {/* feature section - start */}
      <div className="feature-section feature-section-1 feature-section-spacing-3">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-5 offset-lg-0 col-10 offset-1">
                <div className="feature-section-image">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/en/f/f6/Dandi_biyo.gif"
                    className="phone"
                    alt="feature-fore"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <SectionHeading icon="" heading="" subHeading="Gameplay" />
                  <div className="icon-text-1-group">
                    <div className="icon-text-1">
                      <div>
                        <p className="c-grey">
                          Dandi biyo is played by two or more players. The
                          wooden pin is laid across a four-inch (10 cm) deep
                          hole in the ground. One player puts one end of the
                          stick inside the hole and holds the other end. The
                          player jerks the stick against the pin to launch the
                          pin into the air while other players called 'fielders'
                          try to catch the pin. If one of the fielders catch the
                          pin in the air, the turn is over and the catcher takes
                          the stick. If the pin instead hits the ground, that
                          player plays to score. One of the fielders then throws
                          the pin into the hole while the player tries to hit
                          and throw the pin away. If the pin goes into the hole,
                          the player's turn is over and the points accumulated
                          by the player automatically becomes zero. If the pin
                          does not go into the hole, the player plays to score
                          by hitting the pin at one end by the stick.
                        </p>

                        <p className="c-grey">
                          In another version of the game, a circular boundary of
                          about one-meter diameter is drawn on the ground. The
                          player throws the pin into the circle from a distance
                          of about two meters. If the pin lies within the circle
                          the player continues to play and score. To score, a
                          player again knocks the pin in the air. Before it
                          lands, the player hits it as many times as possible,
                          to move it away from the hole. This process is
                          repeated three times in which the player tries to
                          throw the pin as far as he can. The score is
                          calculated by multiplying the number of hits by the
                          number of stick lengths the pin travelled. If the
                          player hits the pin twice in the air, the score will
                          be twice the number of stick lengths the pin
                          travelled.
                        </p>

                        <p className="c-grey">
                          The game was mostly played by Nepali youths and was
                          very popular between the 1980s and 1990s when modern
                          toys and games were not available.
                        </p>

                        <p className="c-grey">
                          Dandi biyo is one of many such games that were locally
                          developed in rural areas reflecting use of local tools
                          and techniques. In the context of modern games, dandi
                          biyo is close to cricket. The player can be compared
                          to the "batsman" and the other players to the
                          "fielders".
                        </p>

                        <p className="c-grey">
                          The Dandi Biyo Association was established about two
                          decades ago as the sport's governing body. A dandi
                          biyo event happens every year on the occasion of
                          Public Service Day, which falls in the first week of
                          September.
                        </p>

                        <p className="c-grey">
                          Tip-cat is a similar British game, and gillidanda is a
                          similar Indian game.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* feature section - end */}
    </>
  );
};

export default Features1;
