import React from "react";

import PageHeader from "../components/PageHeader";
import SectionHeading from "../components/SectionHeading";

const Features1 = () => {
  return (
    <>
      {/* page header - start */}
      <PageHeader title="Kibbel-Kabbel" pages={[]} />
      {/* page header - end */}

      {/* feature section - start */}
      <div className="feature-section feature-section-1 feature-section-spacing-3">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-5 offset-lg-0 col-10 offset-1">
                <div className="feature-section-image">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/58/Kibbel-Kabbel_Spielweise.jpg/1365px-Kibbel-Kabbel_Spielweise.jpg"
                    className="phone"
                    alt="feature-fore"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <SectionHeading
                    icon=""
                    heading=""
                    subHeading="Kibbel-Kabbel"
                  />
                  <div className="icon-text-1-group">
                    <div className="icon-text-1">
                      <div>
                        <p className="c-grey">
                          Kibbel-Kabbel ist ein mit dem Cricket und Guli Danda
                          verwandtes Geschicklichkeitsspiel für den freien
                          Außenbereich.
                        </p>

                        <p className="c-grey">
                          Gespielt wird mit dem Kibbel, einem etwa 10 cm langen
                          beidseitig angespitzten Holz, das mit dem Kabbel,
                          einem Stock, möglichst weit weggeschleudert
                          bzw.geschlagen werden muss. In Deutschland war das
                          Spiel vor allem im Norden populär, in den USA wurde es
                          als Peewee gespielt. Das Spiel wurde z. B. in Hamburg
                          bis in die 1960er Jahre viel gespielt, ist heute
                          jedoch fast in Vergessenheit geraten. Ein Grund dafür
                          ist vermutlich, dass es im urbanen öffentlichen Raum
                          wegen der Zunahme von Bodenversiegelung, ruhendem und
                          rollendem Kfz-Verkehr sehr schwierig wäre, geeignete
                          Spielflächen zu finden
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* feature section - end */}

      {/* feature section - start */}
      <div className="feature-section feature-section-1 feature-section-spacing-3">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-5 offset-lg-0 col-10 offset-1">
                <div className="feature-section-image">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/0/05/Kibbel-Kabbel_Spielteile.jpg"
                    className="phone"
                    alt="feature-fore"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <SectionHeading
                    icon=""
                    heading=""
                    subHeading="Spielfeld und Material"
                  />
                  <div className="icon-text-1-group">
                    <div className="icon-text-1">
                      <div>
                        <p className="c-grey">
                          Für das Spiel wird ein möglichst waagerechtes
                          Spielfeld mit festem, ebenem und bewuchsfreiem Boden
                          von mindestens 20 mal 20 Meter Größe benötigt. Zum
                          Spiel benötigt man zwei Hölzer. Das eine ist der
                          Kibbel, ein etwa 10 cm langer Stab mit einem
                          Durchmesser von etwa 2,5 cm, der an beiden Enden in
                          einem Winkel von etwa 30 Grad angespitzt ist. Der
                          Kibbel kann runden oder auch quadratischen (günstiger
                          bei leicht abschüssigem Spielfeld) Querschnitt haben.
                          Das andere ist der Kabbel, ein etwa 60–70 cm langer
                          Stock mit rundem Querschnitt und etwa 2,5 cm
                          Durchmesser. Das Spielfeld muss mindestens an einer
                          Ecke aus unbefestigtem Boden bestehen, der Rest kann
                          asphaltiert oder anders befestigt sein. In einer Ecke
                          der Spielfläche wird eine kleine, etwa 15–20 cm lange,
                          3–4 cm breite und tiefe Furche (das Loch) als Mal
                          angelegt. Es wird mit dem Kabbel in den Boden geritzt.
                          Neben dem Loch muss genügend unbefestigter Boden zum
                          Einritzen der Punktzahlen beider Mannschaften
                          vorhanden sein, die den Spielstand dokumentieren.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* feature section - end */}

      {/* feature section - start */}
      <div className="feature-section feature-section-1 feature-section-spacing-3">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-5 offset-lg-0 col-10 offset-1">
                <div className="feature-section-image">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/4/43/Kibbel-Kabbel.jpg"
                    className="Phone"
                    alt="feature-fore"
                  />
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <SectionHeading
                    icon=""
                    heading=""
                    subHeading="Regeln und Spielablauf"
                  />
                  <div className="icon-text-1-group">
                    <div className="icon-text-1">
                      <div>
                        <p className="c-grey">
                          Beim Spiel werden neben Geschicklichkeit unbewusst
                          auch Rechnen (Addieren und Multiplizieren,
                          gelegentlich auch Subtrahieren) eingeübt. Die
                          Spielermannschaft ist drinnen oder „am Loch“, die
                          Fängermannschaft draußen. Vor dem Spiel werden Regeln
                          festgelegt, unter anderem, wer als Spieler anfängt,
                          dass gewinnt, wer zuerst 500 oder 1.000 Punkte
                          erreicht, ob „mit Mauser“ gespielt wird u. a. m. Die
                          Mannschaft am Loch schreibt durch Einritzen mit dem
                          Kabbel in die Erde auf der jeweiligen Seite des Lochs
                          unter Aufsicht der anderen Mannschaft die gewonnenen
                          Punkte jeder Mannschaft auf und rechnet sie jeweils
                          zusammen.
                        </p>

                        <p className="c-grey">
                          Zur Ausführung ist der Kibbel quer über das Mal zu
                          legen und vom Spieler mit dem Kabbel, möglichst hoch
                          und weit herauszuschleudern. Der oder die „Fänger“
                          stellen sich zuvor im Spielfeld auf dem
                          wahrscheinlichen Flugweg des Kibbels auf.
                        </p>

                        <p className="c-grey">
                          Von dort, wo der Kibbel liegen bleibt oder gefangen
                          worden ist, ist nun der quer über das Loch gelegte
                          Kabbel abzuwerfen, das heißt, mit dem Kibbel zu
                          treffen. Wurde der Kibbel gefangen muss der Fänger
                          selbst werfen, sonst kann die Mannschaft, die draußen
                          ist wählen, wer werfen soll. Gelingt das Abwerfen, ist
                          der nächste Spieler der Mannschaft am Loch an der
                          Reihe. War das der letzte, kommt die Fängermannschaft
                          ans Loch. Ist der Kabbel nicht getroffen worden, hat
                          der Spieler drei Versuche, den Kibbel von dort, wo er
                          beim Abwerfen liegengeblieben ist, möglichst weit vom
                          Loch wegzuschlagen, denn die Schrittzahl zum Loch
                          zurück zählt Punkte. Dazu muss er durch Schlagen auf
                          eines der angespitzten Enden des Kibbels diesen zum
                          Hochspringen bringen und ihn dann in der Luft mit dem
                          Kabbel wegzuschlagen. Gelingt ihm gar, den Kibbel vor
                          dem Wegschlagen zwei- oder dreimal mit dem Kabbel
                          hochzuschlagen (also in der Luft zu halten), wird die
                          Schrittzahl verdoppelt oder verdreifacht. Ist ihm bei
                          den drei Versuchen noch einmal so ein Doppel gelungen,
                          dann zählt die Schrittzahl vierfach als Punkte usw.
                          Wird „mit Mauser“ gespielt und konnte der Kibbel nach
                          unten geschlagen werden, wird nicht mit normalen
                          Schritten, sondern mit Mäuseschritten gezählt. Ist der
                          Kibbel bei den drei Versuchen nicht hochgesprungen
                          oder nicht getroffen worden, wird wie nach Abwurf
                          gewechselt.
                        </p>

                        <p
                          className="c-grey"
                          style={{ fontWeight: "bold", color: "black" }}
                        >
                          Wertung
                        </p>

                        <p className="c-grey">
                          Der Fänger muss versuchen, den aus dem Mal
                          herausgeschleuderten Kibbel zu fangen, wobei er je
                          nach Abmachung Punkte machen kann – zum Beispiel durch
                          einen Fang mit beiden Händen 25, mit rechter Hand 50,
                          mit linker 100 Punkte. Wer den Kibbel mit dem Mund
                          fängt, bekommt 250 Punkte, mit dem Auge sogar 450
                          Punkte. Weitere mit Punkten bewertete besondere Arten
                          des Fangs können z. B. Zigarre rechts oder Zigarre
                          links sein.
                        </p>
                        <p className="c-grey">
                          Je nach Abmachung kann der geglückte Fang noch dadurch
                          belohnt werden, dass der Fänger vor dem Abwerfen drei
                          Schritte oder Sprünge zum Mal hin machen darf. Zudem
                          können durch ein Schiffchen 50 Punkte gewonnen werden,
                          dabei muss der Kibbel beim Abwerfen längs im Mal
                          liegen bleiben. Bleibt der Kibbel im Loch an den
                          Kabbel angelehnt liegen („Kanone“), kann das z. B. 200
                          Punkte geben. Auch der quer über dem Loch liegende
                          Kibbel („Brücke“) kann z. B. 100 Punkte zählen.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* feature section - end */}

      {/* feature section - start */}
      <div className="feature-section feature-section-1 feature-section-spacing-3">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-10 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <SectionHeading icon="" heading="" subHeading="Variations" />
                  <div className="icon-text-1-group">
                    <div className="icon-text-1">
                      <div>
                        <p className="c-grey">
                          As an amateur youth sport, gilli danda has many
                          regional variations. In some versions, the number of
                          points a striker score depends on the distance the
                          gilli falls from the striking point. The distance is
                          measured in terms of the length of the danda, or in
                          some cases the length of the gilli. Scoring also
                          depends on how many times the gilli was hit in the air
                          in one strike. If it travels a certain distance with
                          two mid-air strikes, the total points are doubled. If
                          the gilli is not struck far enough the player has to
                          pick it up and try again. Shobhit Maurya is a world
                          champion of gilli danda at present with several world
                          records in his name. The UNESCO Advisory committee and
                          the International Council of Traditional Sports and
                          Games (ICTSG) are keen to revive and promote all such
                          traditional sports which are almost dying in the
                          World.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* feature section - end */}
    </>
  );
};

export default Features1;
