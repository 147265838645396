import React from "react";

import PageHeader from "../components/PageHeader";
import SectionHeading from "../components/SectionHeading";

const Features1 = () => {
  return (
    <>
      {/* page header - start */}
      <PageHeader title="Quimbumbia" pages={[]} />
      {/* page header - end */}

      {/* feature section - start */}
      <div className="feature-section feature-section-1 feature-section-spacing-3">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-5 offset-lg-0 col-10 offset-1">
                <div className="feature-section-image">
                  <img
                    src="https://media.americaradiomiami1260am.com/p/2709a0e3c44e99b842e4113ef68f9050/adjuntos/179/imagenes/008/233/0008233781/790x0/smart/465119404_923008f63a_bjpeg.jpeg"
                    className="phone"
                    alt="feature-fore"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <SectionHeading icon="" heading="" subHeading="Cómo jugar" />
                  <div className="icon-text-1-group">
                    <div className="icon-text-1">
                      <div>
                        <p className="c-grey">
                          Un deporte antiquísimo que practicábamos en Cuba era
                          La Quimbumbia.
                        </p>

                        <p className="c-grey">
                          Tienes que usar un palo de 20 pulgadas de largo, que
                          puede ser desde un mocho de escoba hasta hecho de
                          madera fina cubana como white ash o caoba.
                        </p>
                        <p className="c-grey">
                          Un palo de cinco pulgadas de largo tallado de tal
                          manera que luce como dos conos unidos por su base,
                          también puede ser desde un mocho de escoba hasta de
                          maderas finas cubanas. Esa es la Quimbumbia.
                        </p>
                        <p className="c-grey">
                          Marcas en el piso un círculo de dos pies de diámetro y
                          de ahí mides 20 veces el largo del palo largo y lo
                          marcas en el suelo.
                        </p>
                        <p className="c-grey">
                          Entonces pones la Quimbumbia en el redondel, Rec paras
                          al lado de Ella y con el palo le das al extremo de la
                          Quimbumbia que está levantado del suelo, está salta y
                          en el aire le das con el palo. Si la Quimbumbia pasa
                          la marca de los 20 palos por el aire sin tocar el
                          suelo son 4 puntos, si va rodando por el suelo y pasa
                          la marca es un punto.
                        </p>
                        <p className="c-grey">
                          La modalidad más usada es similar a un juego de base
                          ball o “pelota”, con tres o cuatro bases, mientras
                          unos están a la ofensiva tratando de golpear la
                          Quimbumbia y correr las bases, los otros están a la
                          defensiva tratando de capturar la Quimbumbia para
                          poner out “ao” al “bateador”.
                        </p>
                        <p className="c-grey">
                          Nosotros jugábamos a la Quimbumbia con cuatro bases y
                          lo hacíamos en el cruce de la calle Espada con
                          Jovellar (también llamada 27 de Noviembre) siendo las
                          bases las cuatro esquinas del cruce de las dos calles,
                          como es natural parábamos cuando venía un coche y
                          muchas veces el coche esperaba que se produjera la
                          jugada para pasar. Había que tener cuidado porque
                          muchas veces la Quimbumbia salía disparada con mucha
                          velocidad y te podía dar en el cuerpo o la cara
                          produciéndote una lesión bien dolorosa. Siempre lo
                          advertíamos cuando empezábamos a jugar. “Cuidado con
                          la Quimbumbia”.
                        </p>
                        <p className="c-grey">
                          El Titi, Arnaldo el curita, Pedrito el pollero,
                          Sardiñas el cocinero, Wilito lipidia, Amortigua un
                          tercio, no me acuerdo su nombre, pero le decíamos así,
                          esa es la desventaja de decirle nombretes a alguien,
                          te acuerdas de el pero no de su nombre, también
                          estaban Humberto el chicho, Papo Edreira, Alberto el
                          flaco, Bernardino el isleño, Cruz punto guajiro,
                          etc..Todos formábamos dos grupos y jugábamos tremendos
                          partidos de Quimbumbia de cuatro esquinas muy reñidas,
                          hasta teníamos espectadores y algunos apostaban dinero
                          al grupo que pensaban iba a ganar.
                        </p>
                        <p className="c-grey">
                          Cuando nos cansábamos parábamos un rato e íbamos a la
                          cafetería del Bebo a tomarnos un guarapo con limón y
                          comernos un pito de auxilio. El pito de auxilio era
                          una enorme friturita de bacalao riquísima.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* feature section - end */}
    </>
  );
};

export default Features1;
