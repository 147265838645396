export const TRANSLATIONS_ES = {
  page: {
    title: "Museo Internacional de la Billarda",
  },
  banner: {
    title: "Museo Internacional de la Billarda",
    text: "Un Museo único y singular. Recursos naturales y digitalización. Madera de Castiñeiro y códigos QR’s. Un antiguo gallinero derruído y restaurado con piedra y pizarra de la zona. Un proyecto circular.",
  },
  origin: {
    title: "Origen de la billarda",
    subtitle: "El inicio del juego",
    text: "El término billarda viene del francés 'bilard' (bastón de jugar). El origen se remonta a la Edad Media, estando documentado en el Libro de Apolonio (Mester de Clerecía) y en las Cantigas de Santa María de Alfonso X, ambas obras del siglo XIII.",
  },
  player: {
    title: "El jugador más ilustre",
    subtitle: "Alfonso X El Sabio",
    text1:
      "El jugador más ilustre del Castillo de Maceda fue el hijo del rey Fernando III y de Beatriz de Suabia. Nacido en el año 1221 en Toledo, el pequeño Alfonso fue puesto al cuidado del noble García Fernández de Villamayor y de la esposa de este, Mayor Arias, y fue enviado a Galicia para pasar parte de su infancia.",
    text2:
      "Aunque otros municipios como Allariz reivindican esa misma condición, en Maceda no albergan dudas de que sus tierras y su castillo fueron el destino de la comitiva que acompañó al heredero al trono, y dan por seguro que el futuro Alfonso X, que pasaría a la historia con el sobrenombre de El Sabio, vivió en su fortaleza hasta la edad de 11 años.",
    text3:
      "Así lo sustentaría el hecho de que en agradecimiento por los servicios prestados el rey Fernando III concediese una villa en Maceda a García Fernández de Villamayor",
  },
  game: {
    title: "El juego de la Billarda",
    subtitle: "¿Cómo funciona?",
    text1:
      "Fue uno de los juegos más conocidos y populares de Galicia y por lo tanto también en nuestra aldea de Castiñeiro, que pertenece a la parroquia de Villarda (con V) en el municipio ourensano de San Xoán de Río. Ya se jugaba en el siglo XIX, en el periódico de Lugo A Monteira del año 1889 se decía que era un juego de moda actual entre los niños.",
    text2:
      "Además afirmaba que no se sabía quién era el inventor, pero que no debía ser un cualquiera, porque tenía más utilidades de las que parecía, le da agilidad al cuerpo, fuerza al brazo, y enseña a calcular las distancias a ojo.",
    text3:
      "Se practica tanto en Galicia como en otros lugares del país. Así, en Canarias y Extremadura se llama Billarda; en Castilla- León, Estornija; en Asturias, Liriu; en el País Vasco Txirikila y en Cataluña, Bèlit. Internacionalmente podemos encontrarlo en Pakistán y en la India, se llama Guli-danda o Gilidanda; en Corea, Jachigi; en Alemania, Kibbel-kabbel; en Malasia, konda kondi; en Italia, Lippa; en Filipinas, Syatong; en Inglaterra, Escocia e Irlanda y USA Tip-cat o,Piggy; en Eslovenia, Pandolo; en Nepal, Dandi Biyo; en Cuba, Qumbumbia.",
  },
  age: {
    title: "La longevidad",
    subtitle: "Juego como parte de nuestro legado cultural intergeneracional",
    text1:
      "Alfonso X «el Sabio» rey de Castilla, León y Galicia entre 1252 y 1284, era un hombre que apoyaba el conocimiento y que creía que jugar te hacía vivir más años.",
    text2:
      "A Juan Pérez, con 87 años, le llaman el 'patriarca' de la billarda. Reside en Castiñeiro y sigue jugando con quinceañeros de la Ribeira Sacra y el Macizo Central ourensano. ",
    text3:
      "En esta aldea, con 6 censados, ha nacido Aldealista: 'Los pueblos no pueden desaparecer'. Aquí nace también el Museo Internacional de la Billarda, junto a un campo para el juego. Un Museo único y singular. Recursos naturales y digitalización. Madera de Castiñeiro y códigos QR’s. Un antiguo gallinero derruído y restaurado con piedra y pizarra de la zona. Un proyecto circular.",
    text4:
      "El Proyecto de Billarda en el Ayuntamiento De San Xoan de Río ha resultado ser uno de los ganadoras en la XIII edición de los Premios Galicia a la Gestión Deportiva.",
  },
  rules: {
    title: "Reglas",
    subtitle: "¿Cómo se juega a la billarda en Galicia?",
    text1:
      "El objetivo es superar la línea del varal con la billarda. Para esto los palanadores o palanadoras se sitúan en la zona de saque y desde allí golpean la billarda, que está en el suelo, en una de sus puntas para que se eleve. Cuando está en el aire hay que darle otro golpe para desplazarla.",
    text2:
      "El orden de lanzamiento es consecutivo. En cada turno, el jugador o jugadora tiene una única opción válida de golpe, es decir; siempre que toque la billarda con el palán, aunque no se eleve, se considera opción de tiro y el turno pasaría al siguiente. En el momento en que la billarda está en el aire se puede golpear tantas veces como se pueda. Asimismo, cuando la billarda es golpeada después de tocar el suelo, el jugador/a deberá volver al lugar donde tocó por primera vez el suelo.",
    text3:
      "Si la billarda es golpeada y sale directamente fuera del terreno de juego, se deberá recoger y situarla, de nuevo, en el mismo lugar para dar el siguiente golpe. Si antes de salir de los límites d campo toca el terreno de juego, la billarda deberá ser situada sobre la línea, en el lugar p donde salió, siempre que sea por los laterales del campo. En el caso de que la billarda salga por la línea de fondo, deberá recogerse y colocarla en el lugar del último golpe. Por último, cada vez que la billarda entre en la línea del varal se suman puntos.",
  },
  footer: {
    copyright: "Todos los derechos reservados",
  },
};
