import React from "react";

import PageHeader from "../components/PageHeader";
import SectionHeading from "../components/SectionHeading";

const Features1 = () => {
  return (
    <>
      {/* page header - start */}
      <PageHeader title="Lippa" pages={[]} />
      {/* page header - end */}

      {/* feature section - start */}
      <div className="feature-section feature-section-1 feature-section-spacing-3">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-10 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <SectionHeading icon="" heading="" subHeading="Lippa" />
                  <div className="icon-text-1-group">
                    <div className="icon-text-1">
                      <div>
                        <p className="c-grey">
                          La lippa è un antico gioco popolare diffuso dal
                          Mediterraneo occidentale all'India forse arrivato in
                          Europa nel XV secolo. Viene considerato alla stregua
                          di uno sport popolare e periodicamente se ne disputano
                          tornei internazionali a livello agonistico.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* feature section - end */}

      {/* feature section - start */}
      <div className="feature-section feature-section-1 feature-section-spacing-3">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-5 offset-lg-0 col-10 offset-1">
                <div className="feature-section-image">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/a/af/Gilli-danda.jpg"
                    className="Phone"
                    alt="feature-fore"
                  />
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <SectionHeading icon="" heading="" subHeading="Nome" />
                  <div className="icon-text-1-group">
                    <div className="icon-text-1">
                      <div>
                        <p className="c-grey">
                          Il gioco ha differenti nomi in base alla località.
                          Raggruppando in base regionale:
                        </p>
                      </div>
                    </div>

                    <div className="icon-text-1" style={{ marginTop: "20px" }}>
                      <div>
                        <h4 className="c-dark">Toscana</h4>
                        <p className="c-grey">
                          In generale alla lippa oppure a mazzascudo, ma a Prato
                          cibbè, a Livorno e Colle di Val d'Elsa a ghinè, a
                          Firenze arè busè, a Siena a giromuso-fuso.
                        </p>
                      </div>
                    </div>

                    <div className="icon-text-1" style={{ marginTop: "20px" }}>
                      <div>
                        <h4 className="c-dark">Emilia-Romagna</h4>
                        <p className="c-grey">
                          Nel Piacentino śgerla o cìrolo, a Parma a giarè, a
                          Ferrara a lippa pandòn oppure bac e pandòn.
                        </p>
                      </div>
                    </div>

                    <div className="icon-text-1" style={{ marginTop: "20px" }}>
                      <div>
                        <h4 className="c-dark">Lombardia</h4>
                        <p className="c-grey">
                          A Mede ciaramèla, a Mantova s-ciàncol, a Milano a la
                          rella o a la rèla, presso il Lago di Como al ciangòl,
                          a Brescia e Bergamo ciáncol[senza fonte], "lipa" in
                          dialetto pavese a Turago Bordone.
                        </p>
                      </div>
                    </div>

                    <div className="icon-text-1" style={{ marginTop: "20px" }}>
                      <div>
                        <h4 className="c-dark">Veneto</h4>
                        <p className="c-grey">
                          A Verona s-cianco, a Vicenza còncio, a Rovig bindeche,
                          a Venezia a massa e pandolo oppure al pandolo, a
                          Padova pìndolo, a Treviso pito.
                        </p>
                      </div>
                    </div>

                    <div className="icon-text-1" style={{ marginTop: "20px" }}>
                      <div>
                        <h4 className="c-dark">Istria e Trieste</h4>
                        <p className="c-grey">Gioco del pandolo.</p>
                      </div>
                    </div>

                    <div className="icon-text-1" style={{ marginTop: "20px" }}>
                      <div>
                        <h4 className="c-dark">Piemonte</h4>
                        <p className="c-grey">
                          In generale a cirimèla, ma nel Monferrato a lippa
                          sippa., a Garessio cilìu.
                        </p>
                      </div>
                    </div>

                    <div className="icon-text-1" style={{ marginTop: "20px" }}>
                      <div>
                        <h4 className="c-dark">Abruzzo</h4>
                        <p className="c-grey">
                          Tirolò, detto generalmente mazzetta o mazza nella
                          Marsica.
                        </p>
                      </div>
                    </div>

                    <div className="icon-text-1" style={{ marginTop: "20px" }}>
                      <div>
                        <h4 className="c-dark">Puglia</h4>
                        <p className="c-grey">
                          A Bari a pestìcchie, a Bisceglie a mazzarèid, a Gioia
                          del Colle pizzecal, ad Ascoli Satriano mazz'e mašchit,
                          a Manfredonia pundelìcchje, a Foggia màzz 'e bustìche,
                          a San Severo mazz'e pìzze, a Taranto màzz'e spəzzìddə,
                          in alcune località del Salento mazza e pizzarieddhu, A
                          zzeppuri a Mesagne (Brindisi), a Deliceto Mazz e Tritl
                        </p>
                      </div>
                    </div>

                    <div className="icon-text-1" style={{ marginTop: "20px" }}>
                      <div>
                        <h4 className="c-dark">Campania</h4>
                        <p className="c-grey">A Napoli mazza e pìvezo/pìuzo.</p>
                      </div>
                    </div>

                    <div className="icon-text-1" style={{ marginTop: "20px" }}>
                      <div>
                        <h4 className="c-dark">Sicilia</h4>
                        <p className="c-grey">
                          In generale a manciùgghia, ma a Messina ô ligneddu, a
                          Polizzi ô lignuzzu, a San Giuseppe Jato a ligna, a
                          Girgenti a mazzi, a Catania a firredda, a Licata a
                          firlazzeddu, a Riesi a ferra a mme, a Milazzo ô boscu,
                          a Siracusa a l'acitu e a pammu oppure scannellu, a
                          Canicattì tanapapiù, a Palma di Montechiaro taddu, a
                          Borgetto Scanneddi.
                        </p>
                      </div>
                    </div>

                    <div className="icon-text-1" style={{ marginTop: "20px" }}>
                      <div>
                        <h4 className="c-dark">Calabria</h4>
                        <p className="c-grey">
                          A San Donato di Ninea a cavicchiùla, a Cosenza
                          stirìddru, a Castrovillari ed a Serra San
                          Brunospizzingùlu oppure spitizzingùlo, ad Albidona
                          zullàru, a Cellara tugliuoli oppure tugliu, a Spezzano
                          Piccolo vusciu, a Cariati trisculu, a San Giovanni in
                          Fiore tribisièllu, a Rogliano squìglia, a San Marco
                          Argentano crìcchici, a Nocera Terinese pizzu e palu a
                          Sambiase "pizzicu e mazza".
                        </p>
                      </div>
                    </div>

                    <div className="icon-text-1" style={{ marginTop: "20px" }}>
                      <div>
                        <h4 className="c-dark">Trentino-Alto Adige</h4>
                        <p className="c-grey">
                          A Rovereto rumega oppure rumeghèr.
                        </p>
                      </div>
                    </div>

                    <div className="icon-text-1" style={{ marginTop: "20px" }}>
                      <div>
                        <h4 className="c-dark">Lazio</h4>
                        <p className="c-grey">
                          A Roma bastone e nizza, in Provincia di Frosinone
                          zicchia, a Terracina mazza e saràga,a Fondi mazz e
                          mbivz.
                        </p>
                      </div>
                    </div>

                    <div className="icon-text-1" style={{ marginTop: "20px" }}>
                      <div>
                        <h4 className="c-dark">Umbria</h4>
                        <p className="c-grey">A Terni semplicemente nizza.</p>
                      </div>
                    </div>

                    <div className="icon-text-1" style={{ marginTop: "20px" }}>
                      <div>
                        <h4 className="c-dark">Sardegna</h4>
                        <p className="c-grey">
                          Mazzucchèddu o pettiasa e caricciasa, ad Alghero
                          pirumbì.
                        </p>
                      </div>
                    </div>

                    <div className="icon-text-1" style={{ marginTop: "20px" }}>
                      <div>
                        <h4 className="c-dark">Abruzzo</h4>
                        <p className="c-grey">
                          A L'Aquila zirè, a Sulmona mazz buzzella[senza fonte].
                        </p>
                      </div>
                    </div>

                    <div className="icon-text-1" style={{ marginTop: "20px" }}>
                      <div>
                        <h4 className="c-dark">Friuli-Venezia Giulia</h4>
                        <p className="c-grey">
                          In generale pìndul pàndul, lip o ci-bê.
                        </p>
                      </div>
                    </div>

                    <div className="icon-text-1" style={{ marginTop: "20px" }}>
                      <div>
                        <h4 className="c-dark">Abruzzo</h4>
                        <p className="c-grey">A Chieti mazz'e cuzz.</p>
                      </div>
                    </div>

                    <div className="icon-text-1" style={{ marginTop: "20px" }}>
                      <div>
                        <h4 className="c-dark">Molise</h4>
                        <p className="c-grey">
                          A Termoli mazz'e cuzz, ad Agnone mazz'e pizzòtt,a
                          Rotello mazz'e mazzill
                        </p>
                      </div>
                    </div>

                    <div className="icon-text-1" style={{ marginTop: "20px" }}>
                      <div>
                        <h4 className="c-dark">Basilicata</h4>
                        <p className="c-grey">Ad Avigliano, mazz e piccul</p>
                      </div>
                    </div>

                    <div className="icon-text-1" style={{ marginTop: "20px" }}>
                      <div>
                        <h4 className="c-dark">Valle d'Aosta</h4>
                        <p className="c-grey">
                          Ad Antagnod e a Lignod (Ayas), caillà
                        </p>
                      </div>
                    </div>

                    <div className="icon-text-1" style={{ marginTop: "20px" }}>
                      <div>
                        <p className="c-grey">
                          Dal punto di vista etimologico, il termine è poi
                          entrato a far parte della lingua italiana quale
                          sinonimo di «veloce».
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* feature section - end */}

      {/* feature section - start */}
      <div className="feature-section feature-section-1 feature-section-spacing-3">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-10 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <SectionHeading icon="" heading="" subHeading="Regolamento" />
                  <div className="icon-text-1-group">
                    <div className="icon-text-1">
                      <div>
                        <p className="c-grey">
                          Il gioco è effettuato con due pezzi di legno,
                          generalmente ricavati dai manici di una scopa, uno di
                          circa 15 cm in lunghezza con le estremità appuntite
                          (chiamato lippino), l'altro lungo circa mezzo metro
                          chiamato lippa: si traccia a terra un cerchio e un
                          ovale per posizionare il lippino. La tecnica consiste
                          nel colpire con il pezzo lungo il pezzo piccolo su
                          un'estremità per farlo saltare (questo il motivo delle
                          estremità appuntite), quindi colpirlo. Si hanno tre
                          tentativi, il gioco consiste nel lanciare il pezzo
                          piccolo quanto più lontano possibile.
                        </p>

                        <p className="c-grey">
                          Il numero di concorrenti è variabile. La squadra che
                          attacca dispone il bastoncino piccolo e affusolato (la
                          lippa) al centro di un cerchio di raggio pari alla
                          lunghezza del bastone che si usa come mazza; se ne
                          colpisce un'estremità per sollevarla in aria e
                          colpirla con forza una seconda volta per lanciarla il
                          più lontano possibile.
                        </p>

                        <p className="c-grey">
                          La squadra che difende si dispone in modo da afferrare
                          al volo la lippa, cosa piuttosto difficile, se vi si
                          riesce il lanciatore è eliminato. In caso contrario
                          dal punto in cui è finito il lippino, il difensore,
                          tenendola in pugno poi la lancia cercando di colpire
                          il bastone-mazza preventivamente posato dietro il
                          cerchio in direzione del lippino; se viene colpito il
                          lanciatore è eliminato (questa operazione si dice
                          carare).
                        </p>

                        <p className="c-grey">
                          In caso contrario, gli attaccanti hanno tre
                          possibilità per colpire e allontanare il più possibile
                          il lippino dal cerchio dopodiché il lanciatore valuta
                          ad occhio la lunghezza in bastoni-mazza del lancio
                          effettuato, ossia tra il cerchio e il punto raggiunto
                          dal lippino. Dopo i tre colpi l'attaccante chiede
                          quanto il difensore offre, per esempio trenta bastoni:
                          se l'attaccante accetta tale distanza, trenta, si
                          scrive il numero a punteggio. Se non accetta, si
                          controlla se la valutazione è corretta misurandola; se
                          il numero dei bastoni è superiore a trenta, per
                          esempio 35, i punti ottenuti dagli attaccanti saranno
                          il doppio del contato; se saranno meno, gli attaccanti
                          prenderanno quello contato. Di solito si valuta sempre
                          in difetto, ma caratteristica del gioco, in prossimità
                          del finale, è anche rischiare. Finché la squadra che
                          attacca non viene eliminata, continua a battere con
                          rotazione dei giocatori.
                        </p>

                        <p className="c-grey">
                          Il punteggio finale può essere 500 o anche 1000.
                          Dipende dal tempo di gioco e dal numero dei giocatori.
                        </p>

                        <p className="c-grey">
                          In contesti particolari (cortili di quartieri
                          popolari, piazze, ecc.) ci possono essere "regole
                          locali" che attribuiscono punteggi speciali associati
                          al superamento di ostacoli. Ad esempio il superamento
                          in altezza di una casa, o di fili dell'energia
                          elettrica può far attribuire punteggi bonus (es. 1000
                          punti).
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* feature section - end */}
    </>
  );
};

export default Features1;
