import React from "react";

import PageHeader from "../components/PageHeader";
import SectionHeading from "../components/SectionHeading";

const Features1 = () => {
  return (
    <>
      {/* page header - start */}
      <PageHeader title="Pandolo" pages={[]} />
      {/* page header - end */}

      {/* feature section - start */}
      <div className="feature-section feature-section-1 feature-section-spacing-3">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-5 offset-lg-0 col-10 offset-1">
                <div className="feature-section-image">
                  <img
                    src="https://www.istrapedia.hr/media/uploads/images/article_images/term_1519998388_1.jpeg"
                    className="phone"
                    alt="feature-fore"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <SectionHeading icon="" heading="" subHeading="Pandolo" />
                  <div className="icon-text-1-group">
                    <div className="icon-text-1">
                      <div>
                        <p className="c-grey">
                          Pandolo, stara istarska dječja igra, vjerojatno
                          pastirska. Igra se dvjema palicama ili granama.
                        </p>
                        <p className="c-grey">
                          Zašiljen komadić napadač udari palicom tako da on
                          odskoči od tla i u zraku ga pokuša udarcem poslati što
                          dalje u polje, gdje ga braniči pokušavaju zaustaviti.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* feature section - end */}

      {/* feature section - start */}
      <div className="feature-section feature-section-1 feature-section-spacing-3">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-5 offset-lg-0 col-10 offset-1">
                <div className="feature-section-image">
                  <img
                    src="https://www.istrapedia.hr/media/uploads/images/article_images/photo_1519998299_1_573.jpg"
                    className="phone"
                    alt="feature-fore"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <SectionHeading icon="" heading="" subHeading="" />
                  <div className="icon-text-1-group">
                    <div className="icon-text-1">
                      <div>
                        <p className="c-grey">
                          U rimsko doba igra je dobila ime po karakterističnoj
                          bikoničnoj zašiljenosti komada drva. Riječ pandolo u
                          istromletačkom narječju znači i vrstu kruha, sinonim
                          za glupana, blesavka, aluziju na muški spolni organ te
                          ribičko i pomorsko pomagalo. Prostornu raširenost igre
                          potvrđuju njezina mnogobrojna imena, npr. pendul u
                          Pazinu, u Italiji lippa, nizza, ghiarre, u Bosni klis,
                          u Grčkoj xiliki, u Španjolskoj tala, u Francuskoj
                          bâtonnet, u Engleskoj tip-cat, u Rusiji čižia.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* feature section - end */}

      {/* feature section - start */}
      <div className="feature-section feature-section-1 feature-section-spacing-3">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-5 offset-lg-0 col-10 offset-1">
                <div className="feature-section-image">
                  <img
                    src="https://www.istrapedia.hr/media/uploads/images/article_images/photo_1519998350_1_366.jpg"
                    className="phone"
                    alt="feature-fore"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <SectionHeading icon="" heading="" subHeading="" />
                  <div className="icon-text-1-group">
                    <div className="icon-text-1">
                      <div>
                        <p className="c-grey">
                          Zbog opasnosti koju je izazivalo mahanje komadom
                          zašiljena drva, pandolo je bio zabranjen u creskom
                          statutu iz XV. st. te proglasom Mletačke Republike iz
                          1546.
                        </p>
                        <p className="c-grey">
                          Capris – društvo za oživljavanje staroga Kopra od
                          1993. na Titovu trgu u Kopru priređuje turnir u
                          pandolu za pehar sv. Nazarija. U Portorožu, Izoli,
                          Kopru i Puli nastalo je više sličnih klubova.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* feature section - end */}
    </>
  );
};

export default Features1;
