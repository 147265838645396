import React from "react";

import PageHeader from "../components/PageHeader";
import SectionHeading from "../components/SectionHeading";

const Features1 = () => {
  return (
    <>
      {/* page header - start */}
      <PageHeader title="Konda-Kondi" pages={[]} />
      {/* page header - end */}

      {/* feature section - start */}
      <div className="feature-section feature-section-1 feature-section-spacing-3">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-10 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <SectionHeading icon="" heading="" subHeading="Konda-Kondi" />
                  <div className="icon-text-1-group">
                    <div className="icon-text-1">
                      <div>
                        <p className="c-grey">
                          Konda-kondi is one of the traditional game. This game
                          is one of the creations of the Malay community in the
                          old days as a way to entertain and have fun after a
                          long work.In Indian society, it is called 'Kaunda
                          kaundi'. Konda-kondi is a small game hitting with long
                          wooden sticks. Konda-kondi also known as "Perik Mata"
                          requires a sharp mind and strength of the players,
                          when thinking in a safe direction to scrape wood and
                          wood chase dikuis to disable the opponent's side.
                          Konda-kondi game is one of the simple and fun game.
                        </p>

                        <p className="c-grey">
                          There are a lot of version of playing this game.
                          Different states or different town boys and girls have
                          their own set of ways to enjoy them with a different
                          set of rules that goes along with it.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* feature section - end */}

      {/* feature section - start */}
      <div className="feature-section feature-section-1 feature-section-spacing-3">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-5 offset-lg-0 col-10 offset-1">
                <div className="feature-section-image">
                  <img
                    src="http://2.bp.blogspot.com/-p9vWl-0zKNc/UUqWvv1I2gI/AAAAAAAAAG4/bXxc2OndCxk/s1600/kondakondi%255B1%255D.gif"
                    className="phone"
                    alt="feature-fore"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <SectionHeading icon="" heading="" subHeading="How to play" />
                  <div className="icon-text-1-group">
                    <div className="icon-text-1">
                      <div>
                        <p className="c-grey">
                          This game requires a minimum of two players; it's a
                          wonderful pastime for couples who want to spend time
                          in the park without being booked for close proximity.
                          However, it will be best if we play in the big group.
                          To play this game, we should divided into two groups.{" "}
                        </p>
                        <p className="c-grey">
                          Need two sticks of equal length, about 8 to 12 inches
                          long. —One stick will function as a projectile, and
                          the other is a bat to launch said projectile.
                          <p className="c-grey">
                            Dig a hole. If you are in a location where
                            hole-digging is not possible, seek an indent in the
                            ground.{" "}
                          </p>
                          Decide which team will be batting first. From this
                          starting team, choose a single member to begin. Said
                          member will wield the batting stick.{" "}
                        </p>
                        <p className="c-grey">
                          The batting player hooks his/her batting stick under
                          the projectile stick and flicks it up as far as
                          possible. The opposing team must scramble to catch the
                          projectile stick before it hits the ground.{" "}
                        </p>
                        <p className="c-grey">
                          If the opposing team manages to catch the projectile
                          stick, the batting team loses its turn and the teams
                          switch roles.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* feature section - end */}

      {/* feature section - start */}
      <div className="feature-section feature-section-1 feature-section-spacing-3">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-5 offset-lg-0 col-10 offset-1">
                <div className="feature-section-image">
                  <img
                    src="http://4.bp.blogspot.com/-BJXXkTVflAw/UUqWrw3KoVI/AAAAAAAAAGw/-o4n2Hq94oU/s1600/6352320_std.jpg"
                    className="Phone"
                    alt="feature-fore"
                  />
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <SectionHeading
                    icon=""
                    heading=""
                    subHeading="Rule of the game"
                  />
                  <div className="icon-text-1-group">
                    <div className="icon-text-1">
                      <div>
                        <p className="c-grey">
                          During the game, if it can be caught short stick by
                          anyone in the group B, so players flicking short wood
                          treated 'dead'. If a player in the group B managed to
                          catch a short stick with using only one arm, all
                          players in the group A treated 'off' and Group B will
                          start the game anyway. If the opponent's side
                          successfully throw a short stick into the hole, the
                          player flicking a short lumber treated 'off' and had
                          to stop the game, other players from the same team,
                          the next player will continue the game.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* feature section - end */}
    </>
  );
};

export default Features1;
