import React from "react";

import PageHeader from "../components/PageHeader";
import SectionHeading from "../components/SectionHeading";

const Features1 = () => {
  return (
    <>
      {/* page header - start */}
      <PageHeader title="Tip-cat" pages={[]} />
      {/* page header - end */}

      {/* feature section - start */}
      <div className="feature-section feature-section-1 feature-section-spacing-3">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-10 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <SectionHeading icon="" heading="" subHeading="Tip-cat" />
                  <div className="icon-text-1-group">
                    <div className="icon-text-1">
                      <div>
                        <p className="c-grey">
                          Tip-cat (also called cat, cat and dog, one-a-cat,
                          pussy, or piggy) is a pastime which consists of
                          tapping a short billet of wood (usually no more than 8
                          to 15 centimetres (3 to 6 in)) with a larger stick
                          (similar to a baseball bat or broom handle); the
                          shorter piece is tapered or sharpened on both ends so
                          that it can be "tipped up" into the air when struck by
                          the larger, at which point the player attempts to
                          swing or hit it a distance with the larger stick while
                          it is still in the air (similar to swinging at a pitch
                          in baseball or cricket, etc.).
                        </p>

                        <p className="c-grey">
                          There are many varieties of the game, but in the most
                          common, the batter, having placed the billet, or
                          "cat", in a small circle on the ground, tips it into
                          the air and hits it to a distance. His opponent then
                          offers him a certain number of points, based upon his
                          estimate of the number of hops or jumps necessary to
                          cover the distance. If the batter thinks the distance
                          underestimated he is at liberty to decline the offer
                          and measure the distance in jumps, and score the
                          number made.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* feature section - end */}

      {/* feature section - start */}
      <div className="feature-section feature-section-1 feature-section-spacing-3">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-5 offset-lg-0 col-10 offset-1">
                <div className="feature-section-image">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/0/0f/ALPP_-_Tip-Cat.png"
                    className="phone"
                    alt="feature-fore"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <SectionHeading
                    icon=""
                    heading=""
                    subHeading="In popular culture"
                  />
                  <div className="icon-text-1-group">
                    <div className="icon-text-1">
                      <div>
                        <p className="c-grey">
                          Italo Calvino has written a short story "Making Do"
                          (in English, "Chi si contenta" in Italian), published
                          in the collection Numbers in the Dark and Other
                          Stories in which the only thing left legal for the
                          citizens to do is to play tip-cat (Lippa in the
                          Italian), which they do all day, until even that is
                          forbidden them, too. A variant of the Italian 'lippa'
                          is 'lizza'.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* feature section - end */}

      {/* feature section - start */}
      <div className="feature-section feature-section-1 feature-section-spacing-3">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-10 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <SectionHeading icon="" heading="" subHeading="Variations" />
                  <div className="icon-text-1-group">
                    <div className="icon-text-1">
                      <div>
                        <p className="c-grey">
                          In Walsall in the 1950s, an alternative version
                          required a set of stumps and bails, similar to those
                          used in cricket; unlike cricket, these stumps were
                          leant against a convenient wall, as the game was
                          played in the street. The aim was to tip up the cat
                          and then strike it towards the stumps with the object
                          of dislodging the bails. Opposing fielders were
                          allowed to catch the cat in flight.
                        </p>
                        <p className="c-grey">
                          Gillidanda in southern Europe and the Indian
                          subcontinent
                        </p>
                        <p className="c-grey">
                          Mazza e pivezo in the language of Naples, southern
                          Italy
                        </p>
                        <p className="c-grey">Țurca in Romania and Moldavia</p>
                        <p className="c-grey">
                          Cead, or Cleas na Slise in Ireland
                        </p>
                        <p className="c-grey">Jachigi in South Korea.</p>
                        <p className="c-grey">Alak Dolak in Iran.</p>
                        <p className="c-grey">
                          Knurr and Spell in West Yorkshire.
                        </p>
                        <p className="c-grey">
                          Dainty, a local variation of this game played in the
                          Louisville neighborhood of Germantown-Schnitzelburg
                        </p>
                        <p className="c-grey">
                          Nipsi in Pennsylvania Dutch communities.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* feature section - end */}
    </>
  );
};

export default Features1;
