import React from "react";

import PageHeader from "../components/PageHeader";
import SectionHeading from "../components/SectionHeading";

const Features1 = () => {
  return (
    <>
      {/* page header - start */}
      <PageHeader title="Jachigi" pages={[]} />
      {/* page header - end */}

      {/* feature section - start */}
      <div className="feature-section feature-section-1 feature-section-spacing-3">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-10 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <SectionHeading icon="" heading="" subHeading="Jachigi" />
                  <div className="icon-text-1-group">
                    <div className="icon-text-1">
                      <div>
                        <p className="c-grey">
                          Jachigi(자치기) is a South Korean game where a long
                          stick and two short sticks is hit and caught. First, a
                          circular hole is dug on the ground, and a circle is
                          drawn on the outside. After placing a short stick
                          around the outside of the hole, it is hit with the
                          long stick, and the rebounding stick (the short one
                          that was just hit), is hit again with the long stick
                          in mid-air, sending it flying far away.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* feature section - end */}

      {/* feature section - start */}
      <div className="feature-section feature-section-1 feature-section-spacing-3">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-10 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <SectionHeading icon="" heading="" subHeading="History" />
                  <div className="icon-text-1-group">
                    <div className="icon-text-1">
                      <div>
                        <p className="c-grey">
                          Jachigi or ja chigi is said to have originated from
                          the game called gyeokgu, a popular sport in ancient
                          Goryeo used for military purposes. It involved two
                          teams holding sticks, which were used to shoot a ball
                          in between two goal posts set up in the middle of a
                          gyeokgu field. The game resembled the modern-day field
                          hockey sport especially as it was also played on
                          horseback. It was, however, included in the military
                          service examination and training in the Joseon period.
                          The advent of modern warfare, particularly after
                          Joseon's war with Japan, made the gyeokgu irrelevant
                          in armed combat and from then on, it transformed into
                          simpler forms and spread across Korea as popular
                          children's games. It was the basis of the
                          shuttlecock-kicking game and the jachigi. The jachigi
                          game denotes measuring with a stick and hitting. Ja
                          means wooden stick, while chigi means measuring
                          distance. To play effectively, the stick should be
                          about 30 centimeters long and the shorter stick must
                          be 7 centimeters.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* feature section - end */}

      {/* feature section - start */}
      <div className="feature-section feature-section-1 feature-section-spacing-3">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-10 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <SectionHeading
                    icon=""
                    heading=""
                    subHeading="How to play Jachigi"
                  />
                  <div className="icon-text-1-group">
                    <div className="icon-text-1">
                      <div>
                        <p className="c-grey">
                          The rules are to first divide two or more people into
                          two teams, either the offensive area or the defensive
                          area. The offensive side hits the stick and lets it
                          fly; if the opposing team catches it, the offense and
                          the defense switch sides. If there were only two
                          people playing, the player who hit the stick would
                          lose. In the case that it could not be caught, the
                          opposing team picks up the short stick, and throws it
                          toward the hole. Then, the offensive person hits it
                          back. Where the short stick fell, they measure with
                          the long stick. Right then, the long stick becomes one
                          ruler. The name Jachigi is used because of the use of
                          the long stick as a ruler to determine the winner. The
                          longer the distance measured, the higher the score.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* feature section - end */}
    </>
  );
};

export default Features1;
