import React from "react";
import ImageGallery from "react-image-gallery";

import Gallery01 from "../../assets/images/gallery01.jpeg";
import Gallery02 from "../../assets/images/gallery02.jpeg";
import Gallery03 from "../../assets/images/gallery03.jpeg";
import Gallery04 from "../../assets/images/gallery04.jpeg";
import Gallery05 from "../../assets/images/gallery05.jpeg";
import Gallery06 from "../../assets/images/gallery06.jpeg";
import Gallery07 from "../../assets/images/gallery07.jpeg";
import Gallery08 from "../../assets/images/gallery08.jpeg";
import Gallery09 from "../../assets/images/gallery09.jpeg";
import Gallery10 from "../../assets/images/gallery10.jpeg";
import Gallery11 from "../../assets/images/gallery11.jpeg";
import Gallery12 from "../../assets/images/gallery12.jpeg";
import Gallery13 from "../../assets/images/gallery13.jpeg";
import Gallery14 from "../../assets/images/gallery14.jpeg";
import Gallery15 from "../../assets/images/gallery15.jpeg";
import Gallery16 from "../../assets/images/gallery16.jpeg";
import Gallery17 from "../../assets/images/gallery17.jpeg";
import Gallery18 from "../../assets/images/gallery18.jpeg";
import Gallery19 from "../../assets/images/gallery19.jpeg";
import Gallery20 from "../../assets/images/gallery20.jpeg";
import Gallery21 from "../../assets/images/gallery21.jpeg";
import Gallery22 from "../../assets/images/gallery22.jpeg";
import Gallery23 from "../../assets/images/gallery23.jpeg";
import Gallery24 from "../../assets/images/gallery24.jpeg";
import Gallery25 from "../../assets/images/gallery25.jpeg";
import Gallery27 from "../../assets/images/gallery27.jpeg";
import Gallery28 from "../../assets/images/gallery28.jpeg";
import Gallery29 from "../../assets/images/gallery29.jpeg";
import Gallery30 from "../../assets/images/gallery30.jpeg";
import Gallery31 from "../../assets/images/gallery31.jpeg";
import Gallery32 from "../../assets/images/gallery32.jpeg";
import Gallery33 from "../../assets/images/gallery33.jpeg";
import Gallery34 from "../../assets/images/gallery34.jpeg";
import Gallery35 from "../../assets/images/gallery35.jpeg";
import Gallery38 from "../../assets/images/gallery38.jpeg";
import Gallery39 from "../../assets/images/gallery39.jpeg";

const Gallery = () => {
  const images = [
    {
      original: Gallery38,
      thumbnail: Gallery38,
    },
    {
      original: Gallery39,
      thumbnail: Gallery39,
    },
    {
      original: Gallery01,
      thumbnail: Gallery01,
    },
    {
      original: Gallery02,
      thumbnail: Gallery02,
    },
    {
      original: Gallery03,
      thumbnail: Gallery03,
    },
    {
      original: Gallery04,
      thumbnail: Gallery04,
    },
    {
      original: Gallery05,
      thumbnail: Gallery05,
    },
    {
      original: Gallery06,
      thumbnail: Gallery06,
    },
    {
      original: Gallery07,
      thumbnail: Gallery07,
    },
    {
      original: Gallery08,
      thumbnail: Gallery08,
    },
    {
      original: Gallery09,
      thumbnail: Gallery09,
    },
    {
      original: Gallery10,
      thumbnail: Gallery10,
    },
    {
      original: Gallery11,
      thumbnail: Gallery11,
    },

    {
      original: Gallery35,
      thumbnail: Gallery35,
    },
    {
      original: Gallery34,
      thumbnail: Gallery34,
    },
    {
      original: Gallery33,
      thumbnail: Gallery33,
    },
    {
      original: Gallery32,
      thumbnail: Gallery32,
    },
    {
      original: Gallery31,
      thumbnail: Gallery31,
    },
    {
      original: Gallery30,
      thumbnail: Gallery30,
    },
    {
      original: Gallery29,
      thumbnail: Gallery29,
    },
    {
      original: Gallery28,
      thumbnail: Gallery28,
    },
    {
      original: Gallery27,
      thumbnail: Gallery27,
    },
    {
      original: Gallery25,
      thumbnail: Gallery25,
    },
    {
      original: Gallery24,
      thumbnail: Gallery24,
    },
    {
      original: Gallery23,
      thumbnail: Gallery23,
    },
    {
      original: Gallery22,
      thumbnail: Gallery22,
    },
    {
      original: Gallery21,
      thumbnail: Gallery21,
    },
    {
      original: Gallery20,
      thumbnail: Gallery20,
    },
    {
      original: Gallery19,
      thumbnail: Gallery19,
    },
    {
      original: Gallery18,
      thumbnail: Gallery18,
    },
    {
      original: Gallery17,
      thumbnail: Gallery17,
    },
    {
      original: Gallery16,
      thumbnail: Gallery16,
    },
    {
      original: Gallery15,
      thumbnail: Gallery15,
    },
    {
      original: Gallery12,
      thumbnail: Gallery12,
    },
    {
      original: Gallery13,
      thumbnail: Gallery13,
    },
    {
      original: Gallery14,
      thumbnail: Gallery14,
    },
  ];

  return (
    <>
      <div className="feature-section feature-section-1 feature-section-spacing-2">
        <div className="feature-section-wrapper">
          <div className="container">
            <ImageGallery items={images} />;
          </div>
        </div>
      </div>

      <hr />
    </>
  );
};

export default Gallery;
