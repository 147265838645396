import { I18nextProvider } from "react-i18next";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { TRANSLATIONS_ES } from "./es.js";
import { TRANSLATIONS_GAL } from "./gal.js";

i18n.use(initReactI18next).init({
  interpolation: { escapeValue: false }, // React already does escaping
  resources: {
    es: {
      translation: TRANSLATIONS_ES,
    },
    gal: {
      translation: TRANSLATIONS_GAL,
    },
  },
});
if (typeof window !== 'undefined') {
  if (
    !localStorage.getItem("language") ||
    (localStorage.getItem("language") != "es" && localStorage.getItem("language") != "gal")
  ) {
    i18n.changeLanguage("es");
  } else {
    i18n.changeLanguage(localStorage.getItem("language"));
    i18n.changeLanguage("es");
  }
}

