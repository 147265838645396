import React from "react";
import { BrowserRouter } from "react-router-dom";
import Markup from "./markup/Markup";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/style.scss";
import "bootstrap/dist/js/bootstrap.min.js";
import "react-image-gallery/styles/css/image-gallery.css";

const App = () => {
  return (
    <BrowserRouter basename="/">
      <Markup />
    </BrowserRouter>
  );
};

export default App;
