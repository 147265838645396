const blog = [
  {
    thumbnail:
      "https://www.somoscomarca.es/asset/thumbnail,992,558,center,center/media/somoscomarca/images/2023/08/14/2023081419303657800.jpg",
    title:
      "Inaugurado el Museo internacional de Billarda coincidiendo con el III Open Billarda Juan Pérez ",
    date: "15 Ago 2023",
    excerpt:
      "La aldea de Castiñeiro, en San Xoán de Río volvió a reunir a 200 asistentes en la tercera edición del Open Billarda Juan Pérez donde además se inauguró el Museo Internacional de Billarda.",
    url: "https://www.somoscomarca.es/articulo/terras-trives/inaugurado-museo-internacional-billarda-coincidiendo-iii/20230814194801157034.html",
  },
  {
    thumbnail: "https://i3.ytimg.com/vi/0zq-v8eIPV0/maxresdefault.jpg",
    title: "El juego de la billarda llena O Castiñeiro en Río",
    date: "23 Ago 2021",
    excerpt:
      "El domingo, en una apacible tarde de verano, los participantes en la Liga galega de la Ribeira Sacra de billarda iban llegando a la pequeña aldea de O Castiñeiro, en San Xoán de Río, para competir en la nueva edición de este campeonato. ",
    url: "https://www.laregion.es/articulo/verano/juego-billarda-llena-castineiro-rio/202208222124431155169.amp.html",
  },
  {
    thumbnail:
      "https://www.somoscomarca.es/media/somoscomarca/images/2022/08/21/2022082123241868481.jpg",
    title:
      "Castiñeiro reúne a más de 200 personas en el campeonato de billarda",
    date: "21 Ago 2021",
    excerpt:
      "La aldea de Castiñeiro, en San Xoán de Río, acoge este domingo la Liga galega de la Ribeira Sacra de billarda, que reunirá a más de doscientas personas. ",
    url: "https://www.somoscomarca.es/articulo/terras-trives/castineiro-reune-mas-200-personas-campeonato-billarda/20220821134713134168.amp.html",
  },
  {
    thumbnail: "https://osil.info/wp-content/uploads/2021/08/bi1-780x470.png",
    title:
      "O equipo de Río imponse na 2ª xornada da Liga de Billarda Ribeira Sacra ",
    date: "16 Ago 2021",
    excerpt:
      "Cunha rotunda vitoria do equipo de San Xoán de Río estreábase na tarde do domingo 15 de agosto o campo de billarda Juan Pérez na localidade de Castiñeiro (Río). ",
    url: "https://osil.info/o-equipo-de-rio-imponse-na-2a-xornada-da-liga-de-billarda-ribeira-sacra/",
  },
  {
    thumbnail: "https://osil.info/wp-content/uploads/2021/08/billar.png",
    title:
      "Castiñeiro (Río) acollerá este domingo a 2ª xornada da Liga Ribeira Sacra de billarda",
    date: "14 Ago 2021",
    excerpt:
      "A Liga Ribeira Sacra de Billarda, cuxa primeira etapa tiña lugar en Manzaneda, recalará nesta fin de semana en San Xoán de Río.",
    url: "https://osil.info/castineiro-rio-acollera-este-domingo-a-2a-xornada-da-liga-ribeira-sacra-de-billarda/",
  },
  {
    thumbnail:
      "https://www.laregion.es/asset/thumbnail,768,432,center,center/media/laregion/images/2021/08/16/2021081621013747537.jpg",
    title: " Jornada de Billarda en San Xoán de Río ",
    date: "11 Ago 2021",
    excerpt:
      "El equipo local de Río, dirigido por su alcalde, se proclamaba ganador del campeonato seguido de Lúa de Forza.",
    url: "https://www.laregion.es/articulo/trives/campeonato-billarda-san-xoan-rio/202108162304581055235.amp.html",
  },
];

export default blog;
