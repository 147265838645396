import React from "react";
import Button from "../components/Button";
import BackgroundAnimation from "../components/BackgroundAnimation";
import VideoLightBox from "../components/VideoLightBox";
import PageHeader from "../components/PageHeader";
import SectionHeading from "../components/SectionHeading";
import { useTranslation } from "react-i18next";
import "../../languages/i18n.js";

const Features1 = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      {/* page header - start */}
      <PageHeader title={t("rules.title")} pages={[]} />
      {/* page header - end */}

      {/* feature section - start */}
      <div className="feature-section feature-section-1 feature-section-spacing-3">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-5 offset-lg-0 col-10 offset-1">
                <div className="feature-section-image">
                  <img
                    src="https://2img.net/h/imgfz.com/i/S9ymNjW.jpeg"
                    className="phone"
                    alt="feature-fore"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <SectionHeading
                    icon=""
                    heading=""
                    subHeading={t("rules.subtitle")}
                  />
                  <div className="icon-text-1-group">
                    <div className="icon-text-1">
                      <div>
                        <p className="c-grey">{t("rules.text1")}</p>
                        <p className="c-grey">{t("rules.text2")}</p>
                        <p className="c-grey">{t("rules.text3")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* feature section - end */}

      <div className="video-section">
        <div className="video-section-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-1 order-lg-1 col-10 offset-1 order-2">
                <div className="video-section-content">
                  <div className="section-heading section-heading-1 center-responsive c-white">
                    <div className="sub-heading upper ls-1">
                      <h5></h5>
                    </div>
                    <div className="main-heading">
                      <h1></h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 ">
                <div className="video-section-video">
                  <figure>
                    <img
                      className="drop-shadow-1"
                      src="https://i3.ytimg.com/vi/ImhS-WSmpWs/maxresdefault.jpg"
                      width="471"
                      height="472"
                      alt="drop-shadow"
                      style={{ width: "100%", height: "auto" }}
                    />

                    <div className="play">
                      <VideoLightBox URL="https://www.youtube.com/watch?v=ImhS-WSmpWs" />
                    </div>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Features1;
