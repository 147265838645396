import React from "react";

// components
import SectionHeading from "../components/SectionHeading";
import Button from "../components/Button";
import BackgroundAnimation from "../components/BackgroundAnimation";

// images
import hero_phone from "../../assets/images/hero-phone.png";
import artwork from "../../assets/images/Artwork.png";
import feature_phone_1 from "../../assets/images/feature-section-1-phone-1.png";
import feature_image_1 from "../../assets/images/feature-section-1-phone-2.png";
import feature_phone_2 from "../../assets/images/feature-section-2-phone-1.png";
import feature_image_2 from "../../assets/images/feature-section-2-phone-2.png";
import feature_phone_3 from "../../assets/images/feature-section-3-phone-1.png";
import feature_image_3 from "../../assets/images/feature-section-3-phone-2.png";
import feature_phone_4 from "../../assets/images/feature-section-4-phone-1.png";
import feature_image_4 from "../../assets/images/feature-section-4-phone-2.png";
import { useTranslation } from "react-i18next";
import "../../languages/i18n.js";
import Gallery from "../components/gallery";

const Index = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      {/* hero - start */}
      <div className="hero hero-1">
        <div className="hero-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-0 order-lg-1 col-10 offset-1 order-2">
                <div className="hero-content">
                  <h1 className="c-dark">{t("banner.title")}</h1>
                  <p className="large c-grey">{t("banner.text")}</p>
                </div>
              </div>
              <div className="col-lg-6 offset-lg-0 order-lg-2 col-10 offset-1 order-1">
                <div className="hero-image">
                  <img
                    className="drop-shadow"
                    src={hero_phone}
                    alt="hero"
                    style={{ width: "95%", height: "auto" }}
                  />
                  <div className="hero-absolute-image">
                    {/*<img src={artwork} alt="artwork" />*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* hero - end */}

      {/* video 
      <div className="video-section">
        <div className="video-section-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 offset-lg-1 order-lg-1 col-10 offset-1 order-2">
                <div className="video-section-content">
                  <div className="section-heading section-heading-1 center-responsive c-white">
                    <div className="sub-heading upper ls-1">
                      <i className="las la-video"></i>
                      <h5>our video</h5>
                    </div>
                    <div className="main-heading">
                      <h1>
                        Orions is a fast and secure app that was built for both
                        Android and iOS platforms.
                      </h1>
                    </div>
                  </div>
                  <Button to="/contact" content="Get Started" type="button-1" />
                </div>
              </div>
              <div className="col-lg-5 offset-lg-1 order-lg-2 order-1">
                <div className="video-section-video">
                  <figure>
                    <img
                      className="drop-shadow-1"
                      src={video_img}
                      width="471"
                      height="472"
                      alt="drop-shadow"
                    />

                    <div className="play">
                      <VideoLightBox URL="https://www.youtube.com/watch?v=WIl5F5rM5wQ" />
                    </div>
                  </figure>
                </div>
              </div>
            </div>
            <div className="background-pattern background-pattern-radius drop-shadow-1">
              <BackgroundAnimation />
              <div className="background-pattern-gradient"></div>
            </div>
          </div>
        </div>
      </div>
      video - end */}

      {/* Origin */}
      <div className="feature-section feature-section-0 feature-section-spacing-1">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-7 offset-lg-0 order-lg-1 col-md-8 offset-md-2 col-10 offset-1 order-2">
                <div className="feature-section-content">
                  <SectionHeading
                    icon=""
                    heading={t("origin.subtitle")}
                    subHeading={t("origin.title")}
                  />
                  <p className="c-grey">{t("origin.text")}</p>
                </div>
              </div>
              <div className="col-lg-5 offset-lg-0 order-lg-2 col-10 offset-1 order-1">
                <div className="feature-section-image">
                  <img
                    src={feature_image_1}
                    className="image"
                    alt="feature-fore"
                  />
                  <img src={feature_phone_1} className="phone" alt="phone" />
                  <div className="background-pattern background-pattern-radius">
                    <BackgroundAnimation />
                    <div className="background-pattern-gradient"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Origin - end */}

      {/* Player Alfonso X - start */}
      <div className="feature-section feature-section-1 feature-section-spacing-2">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-5 offset-lg-0 col-10 offset-1">
                <div className="feature-section-image">
                  <img
                    src={feature_image_2}
                    className="image"
                    alt="feature-fore"
                  />
                  <img src={feature_phone_2} className="phone" alt="phone" />
                  <div className="background-pattern background-pattern-radius-reverse">
                    <BackgroundAnimation />
                    <div className="background-pattern-gradient"></div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <SectionHeading
                    icon=""
                    heading={t("player.subtitle")}
                    subHeading={t("player.title")}
                  />
                  <div className="icon-text-1-group">
                    <div className="icon-text-1">
                      <div>
                        <p className="c-grey">{t("player.text1")}</p>
                        <p className="c-grey">{t("player.text2")}</p>
                        <p className="c-grey">{t("player.text3")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Player Alfonso X  - end */}

      {/* The game - start */}
      <div className="feature-section feature-section-0 feature-section-spacing-1">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-7 offset-lg-0 order-lg-1 col-md-8 offset-md-2 col-10 offset-1 order-2">
                <div className="feature-section-content">
                  <SectionHeading
                    icon=""
                    heading={t("game.subtitle")}
                    subHeading={t("game.title")}
                  />
                  <p className="c-grey">{t("game.text1")}</p>
                  <p className="c-grey">{t("game.text2")}</p>
                  <p className="c-grey">{t("game.text3")}</p>
                </div>
              </div>
              <div className="col-lg-5 offset-lg-0 order-lg-2 col-10 offset-1 order-1">
                <div className="feature-section-image">
                  <img
                    src={feature_phone_3}
                    className="image"
                    alt="feature-fore"
                  />
                  <img src={feature_image_3} className="phone" alt="phone" />
                  <div className="background-pattern background-pattern-radius">
                    <BackgroundAnimation />
                    <div className="background-pattern-gradient"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* The game - end */}

      {/* Longevity - start */}
      <div className="feature-section feature-section-1 feature-section-spacing-2">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-5 offset-lg-0 col-10 offset-1">
                <div className="feature-section-image">
                  <img
                    src={feature_image_4}
                    className="image"
                    alt="feature-fore"
                  />
                  <img src={feature_phone_4} className="phone" alt="phone" />
                  <div className="background-pattern background-pattern-radius-reverse">
                    <BackgroundAnimation />
                    <div className="background-pattern-gradient"></div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <SectionHeading
                    icon=""
                    heading={t("age.subtitle")}
                    subHeading={t("age.title")}
                  />
                  <div className="icon-text-1-group">
                    <div className="icon-text-1">
                      <div>
                        <p className="c-grey">{t("age.text1")}</p>
                        <p className="c-grey">{t("age.text2")}</p>
                        <p className="c-grey">{t("age.text3")}</p>
                        <p className="c-grey">{t("age.text4")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Longevity - end */}

      <Gallery />
    </>
  );
};

export default Index;
